import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
  UpdatePassword,
  User,
  UserCreate,
  UserFacebookToken,
  UserPassword,
} from '@core/models';
import { environment } from '@env/environment';
import { PageContent, QueryParam } from '@shared/models';

@Injectable({ providedIn: 'root' })
export class UserService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.baseUrl}/v1/user`;
  }

  getAll(param: QueryParam): Observable<PageContent<User>> {
    const params = new HttpParams()
      .append('page', param.page ?? 1)
      .append('size', param.size ?? 50)
      .append('search', param.search ?? '');
    return this.http.get<PageContent<User>>(`${this.baseUrl}/list`, { params });
  }

  get(id: string): Observable<User> {
    return this.http.get<User>(`${this.baseUrl}/${id}`);
  }

  me(): Observable<User> {
    return this.http.get<User>(`${this.baseUrl}/user`);
  }

  create(user: UserCreate): Observable<User> {
    return this.http.post<User>(`${this.baseUrl}`, user);
  }

  update(user: UserCreate | UserFacebookToken): Observable<User> {
    return this.http.put<User>(`${this.baseUrl}/${user.id}`, user);
  }

  delete(id: string): Observable<unknown> {
    return this.http.delete<unknown>(`${this.baseUrl}/${id}`);
  }

  changePassword(password: UserPassword): Observable<UpdatePassword> {
    return this.http.post<UpdatePassword>(`${this.baseUrl}/change_password`, password);
  }

  generateSecretKey(): Observable<{ secretKey: string }> {
    return this.http.post<{ secretKey: string }>(`${this.baseUrl}/secret_key`, {});
  }
}
