import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { InstagramPost, InstagramReaction } from '@core/models';
import { InstagramQuery, PageContent } from '@shared/models';
import { Platform } from '@modules/social/models/platform';

@Injectable({
  providedIn: 'root',
})
export class InstagramService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.baseUrl}/v1/instagram`;
  }

  getPostReaction(query: InstagramQuery): Observable<InstagramReaction> {
    const params = this.httpParams(query);
    return this.http.get<InstagramReaction>(`${this.baseUrl}/media_insights`, { params });
  }

  getPosts(query: InstagramQuery): Observable<InstagramPost[]> {
    const params = this.httpParams(query);
    return this.http.get<InstagramPost[]>(`${this.baseUrl}/medias`, { params });
  }

  private httpParams(query: InstagramQuery): HttpParams {
    let params = new HttpParams()
      .append('dateFrom', `${query?.from}`)
      .append('dateTo', `${query?.to}`);

    if (query?.enterprises && query.enterprises.length) {
      params = params.append('enterprises', query?.enterprises?.join(','));
    }

    if (query?.brands && query.brands.length) {
      params = params.append('brands', query?.brands?.join(','));
    }

    if (query?.hotels && query.hotels.length) {
      params = params.append('hotels', query?.hotels?.join(','));
    }

    params = query?.platformId ? params.append('platformId', query.platformId) : params;

    return params;
  }
}
