import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { LayoutService } from '@layout/service/app.layout.service';
import { AuthService } from "@core/services/auth/auth.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'cwa-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private primeNgConfig: PrimeNGConfig,
    private layoutService: LayoutService,
    private authService: AuthService
  ) {
    if (this.authService.token) {
      this.authService.profile().pipe(takeUntilDestroyed()).subscribe();
    }
  }

  ngOnInit(): void {
    this.primeNgConfig.ripple = true; //enables core ripple functionality

    //optional configuration with the default configuration
    this.layoutService.config = {
      ripple: false, //toggles ripple on and off
      inputStyle: 'outlined', //default style for input elements
      menuMode: 'static', //layout mode of the menu, valid values are "static", "overlay", "slim", "horizontal", "reveal" and "drawer"
      colorScheme: 'light', //color scheme of the template, valid values are "light", "dim" and "dark"
      theme: 'purple', //default component theme for PrimeNG
      menuTheme: 'colorScheme', //theme of the menu, valid values are "colorScheme", "primaryColor" and "transparent"
      scale: 14, //size of the body font size to scale the whole application
    };
  }
}
