import { CommonModule, TitleCasePipe } from '@angular/common';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  EMPTY,
  filter,
  forkJoin,
  lastValueFrom,
  map,
  mergeMap,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import {
  MultiSelectChangeEvent,
  MultiSelectFilterEvent,
  MultiSelectModule,
} from 'primeng/multiselect';

import { EntityRef, GlobalFilter, User } from '@core/models';
import { GlobalFilterRepository } from '@store/global-filter.repository';
import { GlobalPropertyRepository } from '@store/global-properties.repository';
import { Storage } from '@core/utils/storage';
import { NavigationEnd, Router } from '@angular/router';
import { ToastService } from '@layout/service/toast.service';

const MODULES = [
  CommonModule,
  ReactiveFormsModule,
  ButtonModule,
  DialogModule,
  MultiSelectModule,
];

@Component({
  standalone: true,
  selector: 'cwa-global-filter',
  templateUrl: './global-filter.component.html',
  styleUrls: ['./global-filter.component.scss'],
  imports: [...MODULES],
  providers: [TitleCasePipe],
})
export class GlobalFilterComponent implements OnInit, AfterViewInit, OnDestroy {
  // Subject to unsubscribe from observables
  private unsubscribe$: Subject<void> = new Subject<void>();

  visible = false;
  selectSingleHotel!: boolean;

  filterForm: FormGroup<GlobalFilterForm> = this.createForm();

  enterprises$!: Observable<EntityRef[]>;
  brands$!: Observable<EntityRef[]>;
  hotels$!: Observable<EntityRef[]>;

  enterpriseFilter$: Subject<string> = new Subject<string>();
  brandFilter$: Subject<string> = new Subject<string>();
  hotelFilter$: Subject<string> = new Subject<string>();
  maxFilterIdCharecterLimit = 1548
  uuidCharecterLength = 36

  private formatHotelName(hotel: EntityRef): string {
    const maxLength = 28;
    // Check if the 'code' property exists on the hotel object
    const hotelCode = hotel.code || '';

    // Strip the name if it exceeds the maximum length
    const formattedName =
      hotel.name.length > maxLength
        ? hotel.name.substring(0, maxLength) + '..'
        : hotel.name;

    // Combine the hotel code and formatted name
    return `${hotelCode}- ${formattedName}`;
  }

  constructor(
    private fb: FormBuilder,
    private globalPropRepo: GlobalPropertyRepository,
    private globalFilterRepo: GlobalFilterRepository,
    public router: Router,
    private titleCase: TitleCasePipe,
    private toastService: ToastService
  ) {
    this.globalFilterRepo.selectSingleHotel$
      .pipe(
        tap((limit) => {
          this.selectSingleHotel = limit;
        }),
        takeUntilDestroyed()
      )
      .subscribe();

    this.globalFilterRepo.filter$
      .pipe(
        tap((filter) => {
          if (filter) {
            this.filterForm.patchValue({
              enterprises: filter.enterprises,
              brands: filter.brands,
              hotels: filter.hotels,
            });
          }
        }),
        mergeMap((filters) => {
          if (filters?.enterprises?.length || filters?.brands?.length) {
            return this.loadBrandAndHotels(
              filters?.enterprises ?? [],
              filters?.brands ?? []
            );
          }
          return EMPTY;
        }),
        take(1),
        takeUntilDestroyed()
      )
      .subscribe();

    this.enterprises$ = this.globalPropRepo.enterprises$.pipe(
      tap((enterprise) => {
        this.setDefaultEnterprises(enterprise);
      }),
      map((e) => e.map((i) => ({ ...i, name: this.titleCase.transform(i.name) })))
    );
    this.brands$ = this.globalPropRepo.brands$.pipe(
      map((e) => e.map((i) => ({ ...i, name: this.titleCase.transform(i.name) })))
    );

    // Inside the GlobalFilterComponent constructor
    this.hotels$ = this.globalPropRepo.hotels$.pipe(
      map((hotels) => {
        return hotels
          .filter((hotel: EntityRef) => hotel.isActive === true)
          .map((hotel) => ({
            ...(hotel as EntityRef), // Use the extended interface
            name: this.formatHotelName(hotel),
          }));
      })
    );

    // Load Enterprise and related Brands
    this.globalPropRepo.fetchEnterprises().pipe(takeUntilDestroyed()).subscribe();

    // region Filter Properties by Text

    this.enterpriseFilter$
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((search) => this.globalPropRepo.fetchEnterprises(search)),
        takeUntilDestroyed()
      )
      .subscribe();

    this.brandFilter$
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        map((search) => {
          const enterprises = this.filterForm.value.enterprises ?? [];
          return { enterprises, search };
        }),
        switchMap(({ enterprises, search }) =>
          this.globalPropRepo.fetchBrands(enterprises, search)
        ),
        tap((brands) => this.globalPropRepo.setBrands(brands)),
        takeUntilDestroyed()
      )
      .subscribe();

    this.hotelFilter$
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        map((search) => {
          const brands = this.filterForm.value.brands ?? [];
          const enterprises = this.filterForm.value.enterprises ?? [];
          return { brands, enterprises, search };
        }),
        switchMap(({ brands, enterprises, search }) =>
          this.globalPropRepo.fetchHotels(brands, enterprises, search)
        ),
        tap((hotels) => this.globalPropRepo.setHotels(hotels)),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  // endregion
  private async setDefaultEnterprises(enterprise: EntityRef[]): Promise<void> {
    const storage = Storage.getItem<User>('authUser');
    const roleName = storage?.role.name;
    try {
      if (enterprise && enterprise.length && roleName?.toLowerCase() === 'user') {
        const ids = enterprise.map((e) => e.id);
        await lastValueFrom(this.loadBrandAndHotels(ids));
        this.filterForm.get('enterprises')?.disable();
        const value = this.filterForm.value as GlobalFilter;
        value.enterprises = ids;
        this.globalFilterRepo.setFilter(value);
      }
    } catch (error) {
      console.error(error);
    }
  }

  private loadBrandAndHotels(enterpriseIds: string[], brandIds?: string[] | null) {
    const brands$ = this.globalPropRepo.fetchBrands(enterpriseIds);

    const hotels$ = this.globalPropRepo.fetchHotels(brandIds ?? [], enterpriseIds);

    return forkJoin([brands$, hotels$]).pipe(catchError(() => of([])));
  }

  private calcualteFilterIdCharecterLength(items: string[]){
    if(items == null || items == undefined){
      items = []
    }

    return items.length * this.uuidCharecterLength
  }


  private calculateEnterpriseLengthLimit(){
    const value = this.filterForm.value as GlobalFilter;
    const brandLength = this.calcualteFilterIdCharecterLength(value.brands)
    const hotelLength = this.calcualteFilterIdCharecterLength(value.hotels)
    const maxLength = Math.floor((this.maxFilterIdCharecterLimit -brandLength - hotelLength) / this.uuidCharecterLength)

    return maxLength
  }

  private calculateBrandLengthLimit(){
    const value = this.filterForm.value as GlobalFilter;
    const enterpriseLength = this.calcualteFilterIdCharecterLength(value.enterprises)
    const hotelLength = this.calcualteFilterIdCharecterLength(value.hotels)
    const maxLength = Math.floor((this.maxFilterIdCharecterLimit -enterpriseLength - hotelLength) / this.uuidCharecterLength)

    return maxLength
  }

  private calculateHotelLengthLimit(){
    const value = this.filterForm.value as GlobalFilter;
    const brandLength = this.calcualteFilterIdCharecterLength(value.brands)
    const enterpriseLength = this.calcualteFilterIdCharecterLength(value.enterprises)
    const maxLength = Math.floor((this.maxFilterIdCharecterLimit -brandLength - enterpriseLength) / this.uuidCharecterLength)

    return maxLength
  }


  visibleChange(visible: boolean): void {
    this.globalFilterRepo.setVisible(visible);
  }

  async selectEnterprise(event: MultiSelectChangeEvent): Promise<void> {
    const maxEnterpriseFilterLength = this.calculateEnterpriseLengthLimit()
    
    if (event.value.length > maxEnterpriseFilterLength) {
      this.toastService.showWarn('Warning', `You can select only ${maxEnterpriseFilterLength} enterprises.`)
      this.filterForm.get('enterprises')?.setValue(event.value.slice(0, maxEnterpriseFilterLength));
      event.value = event.value.slice(0, maxEnterpriseFilterLength)
    }

    this.globalPropRepo.setBrands([]);
    this.globalPropRepo.setHotels([]);
    this.filterForm.get('brands')?.reset([]);
    this.filterForm.get('hotels')?.reset([]);

    if (event.value && event.value.length) {
      await lastValueFrom(this.loadBrandAndHotels(event.value));
    }

    const value = this.filterForm.value as GlobalFilter;
    value.enterprises = event.value;
    this.globalFilterRepo.setFilter(value);
  }

  async selectBrand(event: MultiSelectChangeEvent): Promise<void> {
    const maxBrandFilterLength = this.calculateBrandLengthLimit()
    
    if (event.value.length > maxBrandFilterLength) {
      this.toastService.showWarn('Warning', `You can select only ${maxBrandFilterLength} brands.`)
      this.filterForm.get('brands')?.setValue(event.value.slice(0, maxBrandFilterLength));
      event.value = event.value.slice(0, maxBrandFilterLength)
    }

    this.globalPropRepo.setHotels([]);
    this.filterForm.get('hotels')?.reset();

    if (event.value && event.value.length) {
      const enterprise = this.filterForm?.get('enterprises')?.value ?? [];
      await lastValueFrom(this.globalPropRepo.fetchHotels(event.value, enterprise));
    }

    const value = this.filterForm.value as GlobalFilter;
    value.brands = event.value;
    this.globalFilterRepo.setFilter(value);
  }

  async selectHotel(event: MultiSelectChangeEvent): Promise<void> {
    const maxHotelFilterLength = this.calculateHotelLengthLimit()
    
    if (event.value.length > maxHotelFilterLength) {
      this.toastService.showWarn('Warning', `You can select only ${maxHotelFilterLength} hotels.`)
      this.filterForm.get('hotels')?.setValue(event.value.slice(0, maxHotelFilterLength));
      event.value = event.value.slice(0, maxHotelFilterLength)
    }

    if (this.selectSingleHotel) {
      this.filterForm.get('hotels')?.setValue([event.itemValue]);
      const value = this.filterForm.value as GlobalFilter;
      this.globalFilterRepo.setFilter(value);
    } else {
      const value = this.filterForm.value as GlobalFilter;
      value.hotels = event.value;
      this.globalFilterRepo.setFilter(value);
    }
  }

  filterEnterprise($event: MultiSelectFilterEvent) {
    this.enterpriseFilter$.next($event.filter);
  }

  filterBrand($event: MultiSelectFilterEvent) {
    this.brandFilter$.next($event.filter);
  }

  filterHotel($event: MultiSelectFilterEvent) {
    this.hotelFilter$.next($event.filter);
  }

  setFirstHotelOnPropertyReport(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd), // Filter only NavigationEnd events
        takeUntil(this.unsubscribe$) // Unsubscribe when component is destroyed
      )
      .subscribe((event: any) => {
        if (event.url === '/report/property') {
          this.hotels$.subscribe(() => {
            const globalFilter: any = Storage.getItem('globalFilter');
            if (globalFilter && globalFilter.filter && globalFilter.filter.hotels) {
              const firstHotelId = globalFilter?.filter?.hotels[0] ?? null;
              if (firstHotelId) {
                this.filterForm.get('hotels')?.setValue([firstHotelId]);
                const value = this.filterForm.value as GlobalFilter;
                this.globalFilterRepo.setFilter(value);
              }
            }
          });
        }
      });
  }

  ngOnInit(): void {
    this.setFirstHotelOnPropertyReport();
  }

  ngAfterViewInit(): void {
    this.setFirstHotelOnPropertyReport();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(); // Complete all subscriptions
    this.unsubscribe$.complete();
  }

  private createForm(): FormGroup<GlobalFilterForm> {
    return this.fb.group(<GlobalFilterForm>{
      enterprises: this.fb.control([]),
      brands: this.fb.control([]),
      hotels: this.fb.control([]),
    });
  }
}

interface GlobalFilterForm {
  enterprises: FormControl<string[]>;
  brands: FormControl<string[]>;
  hotels: FormControl<string[]>;
}
