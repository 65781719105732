export const environment = {
  production: true,
  baseUrl: 'https://api.cogwheelanalytics.com/api',
  facebook: {
    appId: '599907791161411',
    cookie: true,
    xfbml: true,
    version: 'v18.0'
  },
  knowledgeBase: {
    cogwheelAnalytic: 'https://docs.google.com/document/d/1C86e4ro1neUOb5s2q79x5jZxOqNXFdFAI6sJbLLw3Lw/edit?usp=sharing'
  },
  hotelRequest: {
    addHotel: 'https://forms.clickup.com/10554421/f/a231n-61251/2UPU35HNZNRCRG4BPB',
    removeHotel: 'https://forms.clickup.com/10554421/f/a231n-62591/CS6XQ8AKPS6DGBKZL4'
  },
  sentry: {
    dsn: "https://91c4ebe69d25894df101f60125a04613@o4507961791938560.ingest.us.sentry.io/4507967678906368"
  }
};
