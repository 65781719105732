import { Component, DestroyRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { catchError, of, tap } from 'rxjs';
import { Message } from 'primeng/api';

import { LayoutService } from '@layout/service/app.layout.service';
import { AuthService } from '@core/services/auth/auth.service';
import { UserLogin } from '@core/services/auth/models/user-login';
import { ToastService } from '@layout/service/toast.service';
import { Title } from '@angular/platform-browser';

interface LoginForm {
  email: FormControl<string>;
  password: FormControl<string>;
}

@Component({
  selector: 'cwa-login',
  templateUrl: 'login.component.html',
})
export class LoginComponent {
  loading = false;
  messages!: Message[]; // Show error message
  visible = false;
  userId = '';

  loginForm: FormGroup<LoginForm> = this.createForm();
  controls = {
    email: this.loginForm.get('email'),
    password: this.loginForm.get('password'),
  };

  constructor(
    private fb: FormBuilder,
    private destroyRef: DestroyRef,
    private router: Router,
    private layoutService: LayoutService,
    private authService: AuthService,
    private toast: ToastService,
    private titleService: Title
  ) {
    this.titleService.setTitle('Auth - Login | Cogwheel Analytics Tools');
}

  get dark(): boolean {
    return this.layoutService.config.colorScheme !== 'light';
  }

  login(): void {
    if (this.loginForm.invalid) return;

    const { email, password } = this.loginForm.value;

    if (email && password) {
      const credential: UserLogin = { email, password };

      this.loading = true;
      this.authService
        .login(credential)
        .pipe(
          tap((user: any) => {
            if (user && user?.isAcceptedTnc) {
              this.router.navigateByUrl('/');
            } else {
              this.userId = user.id;
              this.showDialog();
            }
          }),
          catchError((error) => {
            this.loading = false;
            this.showError(error.error?.detail);
            return of(null);
          }),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe();
    }
  }

  private showError(detail: string): void {
    this.messages = [
      {
        severity: 'error',
        detail,
      },
    ];
  }

  showDialog() {
    this.visible = true;
  }

  doAgree() {
    this.authService
      .updateTnCAgreement(this.userId, { isAcceptedTnc: true })
      .pipe(
        catchError((error) => {
          console.error('Error updating T&C agreement:', error);
          // Handle error if needed
          return of(null);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.visible = false;
        this.toast.showSuccess('You agreed with our T&C and Policy', '');
        this.router.navigateByUrl('/');
      });
  }

  cancelAgree() {
    this.visible = false;
    this.router.navigateByUrl('/login');
    this.loading = false;
  }

  private createForm(): FormGroup<LoginForm> {
    return this.fb.group(<LoginForm>{
      email: this.fb.control('', [Validators.email, Validators.required]),
      password: this.fb.control('', [Validators.required]),
    });
  }
}
