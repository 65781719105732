<p-dialog
  id="globalDialog"
  [visible]="true"
  [resizable]="false"
  [draggable]="false"
  position="topright"
  [closable]="false"
  (visibleChange)="visibleChange($event)">
  <form [formGroup]="filterForm" >
    <div class="formgrid grid w-4 flex-nowrap">
      <div class="field mb-0 mr-2">
        <p-multiSelect
          class="uppercase"
          [options]="$any((enterprises$ | async) ?? [])"
          appendTo="body"
          optionLabel="name"
          optionValue="id"
          display="chip"
          formControlName="enterprises"
          [filter]="true"
          [lazy]="true"
          [resetFilterOnHide]="true"
          (onChange)="selectEnterprise($event)"
          (onFilter)="filterEnterprise($event)"
          [style]="{'minWidth':'200px', 'width': '200px'}"
          placeholder="Select Enterprises" />
      </div>

      <div class="field mb-0 mr-2">
        <p-multiSelect
          class="uppercase"
          [options]="$any((brands$ | async) ?? [])"
          appendTo="body"
          optionLabel="name"
          optionValue="id"
          display="chip"
          formControlName="brands"
          [filter]="true"
          [lazy]="true"
          [resetFilterOnHide]="true"
          (onChange)="selectBrand($event)"
          (onFilter)="filterBrand($event)"
          [style]="{'minWidth':'180px', 'width': '180px'}"
          placeholder="Select Brands" />
      </div>

      <div class="field mb-0">
        <p-multiSelect
          (onChange)="selectHotel($event)"
          (onFilter)="filterHotel($event)"
          [filter]="true"
          [lazy]="true"
          [options]="$any((hotels$ | async) ?? [])"
          [style]="{'minWidth':'180px', 'width': '180px'}"
          [panelStyle]="{ minWidth: '30em' }"
          [virtualScrollItemSize]="50"
          [virtualScroll]="true"
          [showToggleAll]="!selectSingleHotel"
          [resetFilterOnHide]="true"
          appendTo="body"
          class="uppercase"
          display="chip"
          formControlName="hotels"
          optionLabel="name"
          optionValue="id"
          placeholder="Select Hotels" />
      </div>
    </div>
  </form>
</p-dialog>
