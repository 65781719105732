import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '@env/environment';


export interface BenchmarkScore {
  aggregated_score: number | null;
  historical_scores: Record<number, number>;
}
export interface BenchmarkData {
  current_kpis: Record<string, number>;
  average_kpis: Record<string, number>;
  index_scores: Record<string, number>;
  personal_final_score: number | null;
  benchmark_final_score: number | BenchmarkScore;
  previous_personal_final_score: number | null;
  previous_benchmark_final_score: number | BenchmarkScore;
  previous_month: string;
  rolling_12_month_personal_final_scores: Array<{ date: string; personal_final_score: number | null }>;
  rolling_12_month_benchmark_final_scores: Array<{ date: string; benchmark_final_score: number | BenchmarkScore }>;
  benchmark_sample_size: number | null;
}

export interface ScorecardResponse {
  benchmark1: BenchmarkData;
  benchmark2: BenchmarkData;
  benchmark3: BenchmarkData;
}

@Injectable({
  providedIn: 'root',
})
export class ScorecardService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.baseUrl}/v1/scorecard`;
  }

  getScorecard(hotelId: string, date: string, enterpriseId?: string | null): Observable<ScorecardResponse> {
    const url = `${this.baseUrl}/scorecard`;
    let params = new HttpParams()
      .set('hotel_id', hotelId)
      .set('date', date);

    if (enterpriseId) {
      params = params.set('enterprise_id', enterpriseId);
    }

    return this.http.get<ScorecardResponse>(url, { params }).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    console.error('An error occurred:', error);
    let errorMessage = 'An unknown error occurred';
    if (error.error instanceof ErrorEvent) {
      // Client-side or network error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Backend returned an unsuccessful response code
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => new Error(errorMessage));
  }
}