import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { Enterprise } from '@core/models/enterprise';
import { PageContent, QueryParam } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class EnterpriseService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.baseUrl}/v1/enterprise`;
  }

  getAll(param: QueryParam): Observable<PageContent<Enterprise>> {
    const params = new HttpParams()
      .append('page', param.page ?? 1)
      .append('size', param.size ?? 50)
      .append('is_master', param.is_master ?? false)
      .append('search', param.search ?? '');

    return this.http.get<PageContent<Enterprise>>(this.baseUrl, { params });
  }

  create(enterprise: Enterprise, file?: File): Observable<Enterprise> {
    const formData = new FormData();
    for (const key in enterprise) {
      const value = enterprise[key as keyof Enterprise] ?? '';
      formData.append(key, String(value));
    }
    if (file) formData.append('logo', file, file.name);

    return this.http.post<Enterprise>(this.baseUrl, formData);
  }

  update(enterprise: Enterprise, file?: File): Observable<Enterprise> {
    const formData = new FormData();
    for (const key in enterprise) {
      const value = enterprise[key as keyof Enterprise] ?? '';
      formData.append(key, String(value));
    }
    if (file) formData.append('logo', file, file.name);

    return this.http.put<Enterprise>(`${this.baseUrl}/${enterprise.id}`, formData);
  }

  delete(enterprise: Enterprise): Observable<unknown> {
    return this.http.delete<unknown>(`${this.baseUrl}/${enterprise.id}`);
  }
}
