import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';

import { CoreModule } from '@core/core.module';
import { AppLayoutModule } from '@layout/app.layout.module';
import { AuthModule } from '@modules/auth/auth.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InMemoryDataService } from './data/in-memory-data.service';

import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";

const CORE_MODULES = [
  BrowserModule,
  ReactiveFormsModule,
  HttpClientModule,
  CoreModule,
  AppRoutingModule,
  AppLayoutModule,
  AuthModule,
  HttpClientInMemoryWebApiModule.forRoot(InMemoryDataService, {
    dataEncapsulation: false,
    passThruUnknownUrl: true,
  }),
];

@NgModule({
  declarations: [AppComponent],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  imports: [...CORE_MODULES],
  bootstrap: [AppComponent],
})
export class AppModule {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(trace: Sentry.TraceService) {}
}
